import React from "react";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { apiFetchChild } from "../../util/api.js";
import { adminApiPortalList } from "../../util/adminApi.js";
import { EditChild } from "./Edit-Child";
import { injectIntl } from "react-intl";
import { withAppContext } from "../withAppContext";

const ItemImpl = ({
    item: child,
    updateItem,
    deleteItem,
    age: globalAge,
    intl,
    availablePortals,
}) => {
    const { formatMessage } = intl;
    const [isEditing, setIsEditing] = useState(false);

    return (
        <>
            {isEditing ? (
                <EditChild
                    child={child}
                    updateItem={updateItem}
                    deleteItem={deleteItem}
                    setIsEditing={setIsEditing}
                    globalAge={globalAge}
                    availablePortals={availablePortals}
                />
            ) : (
                <tr>
                    <td>{child.name}</td>
                    <td>
                        {formatMessage({ id: `children.ages.${child.age}` })}
                    </td>
                    <td>
                        {!child.ips.length && <i>᠆</i>}

                        {child.ips.map((ip, index) => (
                            <span
                                key={`ip-${ip.uuid}`}
                                className={ip.dyndns ? "tooltip" : undefined}
                                data-tooltip={
                                    ip.dyndns
                                        ? "Dynamische IP\nnicht änderbar"
                                        : undefined
                                }
                                style={{
                                    fontStyle: ip.dyndns ? "italic" : "initial",
                                    opacity: ip.dyndns ? 0.7 : 1,
                                    cursor: "default",
                                }}
                            >
                                {ip.ip}
                                {index < child.ips.length - 1 ? ", " : ""}
                            </span>
                        ))}
                    </td>
                    <td>
                        {child.portals &&
                            ((portals) => {
                                return portals.map((portal, index) => (
                                    <span key={`portal-${portal.name}`}>
                                        {portal.name}
                                        {index < portals.length - 1 ? ", " : ""}
                                    </span>
                                ));
                            })(child.portals.filter(({ active }) => active))}
                    </td>

                    {updateItem && (
                        <td style={{ textAlign: "right" }}>
                            <button
                                className="btn btn-default btn-sm mr-2"
                                title={formatMessage({ id: "edit" })}
                                onClick={() => setIsEditing(true)}
                            >
                                <i className="icon icon-edit" />
                            </button>
                            <button
                                className="btn btn-error btn-sm"
                                title={formatMessage({ id: "delete" })}
                                onClick={() =>
                                    window.confirm(
                                        formatMessage({
                                            id: "are-you-sure",
                                        }),
                                    ) && deleteItem(child.id)
                                }
                            >
                                <i className="icon icon-delete" />
                            </button>
                        </td>
                    )}
                </tr>
            )}
        </>
    );
};

export const Item = withAppContext(injectIntl(ItemImpl));
