import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { withAppContext } from "../withAppContext";
import { FormattedMessage, injectIntl } from "react-intl";

import { v4 as uuid } from "uuid";
import { z } from "zod";

import { List } from "../List/List";
import { PortalSettings } from "../PortalSettings/portal-settings";

/**
 * @function Edit
 * @param {{ child: API.Child; availablePortals: API.PortalObject[], setIsEditing(value: boolean): void; updateItem(id: string, child: API.Child): Promise<void>; deleteItem(id: string): void; globalAge: number; } & ReactIntl.InjectedIntlProps} props
 */
const Edit = ({ intl: { formatMessage }, ...props }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: props.child.name,
            age: props.child.age,
            ageXml: props.child.ageXml,
            apiOnTheFly: props.child.apiOnTheFly,
        },
    });

    const [portals, setPortals] = useState(props.child.portals);

    const [ips, setIps] = useState(
        props.child.ips.map((ip) => ({ ...ip, uuid: uuid() })),
    );

    const dynamicIps = useMemo(() => ips.filter(({ dyndns }) => dyndns), [ips]);
    const staticIps = useMemo(() => ips.filter(({ dyndns }) => !dyndns), [ips]);

    /**@type {Parameters<typeof handleSubmit>[0]} */
    // @ts-ignore
    const onValid = (data, event) => {
        props
            .updateItem(props.child.id, {
                ...props.child,
                ...data,
                ips,
                portals,
            })
            .then(() => props.setIsEditing(false));
    };

    /**@type {Parameters<typeof handleSubmit>[1]} */
    // @ts-ignore
    const onInvalid = (errors, event) => {
        // TODO: Show alert
    };

    return (
        <tr>
            <td colSpan={6}>
                <form
                    className="form-horizontal"
                    onSubmit={handleSubmit(onValid, onInvalid)}
                >
                    <div className="form-group">
                        <div className="col-3 col-sm-12">
                            <label className="form-label" htmlFor="name">
                                Name
                            </label>
                        </div>
                        <div className="col-9 col-sm-12">
                            <input
                                className={`form-input ${errors.name ? "is-error" : "is-success"}`}
                                type="text"
                                id="name"
                                placeholder="Name"
                                {...register("name", {
                                    validate: (value) =>
                                        z.string().nonempty().safeParse(value)
                                            .success &&
                                        value.trim() !== "admin",
                                })}
                            />
                            {errors.name && (
                                <p className="form-input-hint">
                                    The name is invalid or reserved.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-3 col-sm-12">
                            <label className="form-label" htmlFor="age">
                                Age
                            </label>
                        </div>
                        <div className="col-9 col-sm-12">
                            <select
                                className={`form-select ${errors.age ? "is-error" : "is-success"}`}
                                name="age"
                                id="age"
                                {...register("age")}
                            >
                                <option value="0">
                                    {formatMessage({ id: "children.ages.0" })}
                                </option>
                                <option value="6">
                                    {formatMessage({ id: "children.ages.6" })}
                                </option>
                                <option value="12" defaultChecked>
                                    {formatMessage({ id: "children.ages.12" })}
                                </option>
                                <option value="16">
                                    {formatMessage({ id: "children.ages.16" })}
                                </option>
                                <option value="18">
                                    {formatMessage({ id: "children.ages.18" })}
                                </option>
                            </select>
                        </div>
                    </div>
                    {dynamicIps.length > 0 && (
                        <div className="form-group">
                            <div className="col-3 col-sm-12">
                                <label className="form-label" htmlFor="age">
                                    Dynamic IPs
                                </label>
                            </div>

                            <div className="col-9 col-sm-12">
                                <List
                                    items={dynamicIps}
                                    accessor={({ ip }) => ip}
                                    addItem={false}
                                    updateItem={false}
                                    removeItem={(uuid) => {
                                        setIps((old) =>
                                            old.filter(
                                                (ip) => ip.uuid !== uuid,
                                            ),
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-group">
                        <div className="col-3 col-sm-12">
                            <label className="form-label" htmlFor="age">
                                Static IPs
                            </label>
                        </div>
                        <div className="col-9 col-sm-12">
                            <List
                                items={staticIps}
                                accessor={({ ip }) => ip}
                                validator={(item) => {
                                    return z.string().ip().safeParse(item).error
                                        ?.issues?.[0]?.message;
                                }}
                                addItem={() => {
                                    setIps((old) => [
                                        ...old,
                                        {
                                            ip: "",
                                            dyndns: false,
                                            uuid: uuid(),
                                        },
                                    ]);
                                }}
                                updateItem={(uuid, value) => {
                                    setIps((old) =>
                                        old.map((ip) =>
                                            ip.uuid === uuid
                                                ? { ...ip, ip: value }
                                                : ip,
                                        ),
                                    );
                                }}
                                removeItem={(uuid) => {
                                    setIps((old) =>
                                        old.filter((ip) => ip.uuid !== uuid),
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <h4>Safety functions</h4>
                    <div className="form-group">
                        <label className="form-checkbox">
                            <input type="checkbox" {...register("ageXml")} />
                            <i className="form-icon"></i>{" "}
                            {formatMessage({ id: "settings.use_age_xml" })}
                        </label>
                    </div>
                    <div className="form-group">
                        <label className="form-checkbox">
                            <input
                                type="checkbox"
                                {...register("apiOnTheFly")}
                            />
                            <i className="form-icon"></i>{" "}
                            {formatMessage({ id: "settings.use_otf" })}
                        </label>
                    </div>

                    {props.availablePortals &&
                        props.availablePortals.length > 0 && (
                            <>
                                <h4>Online Portals</h4>
                                {props.availablePortals.map((portal) => {
                                    const currentPortal = portals.find(
                                        (p) => p.name === portal.name,
                                    );

                                    return (
                                        <PortalSettings
                                            key={portal.name}
                                            name={portal.name}
                                            allowed={
                                                currentPortal?.active ?? false
                                            }
                                            safeSearch={
                                                currentPortal?.safeSearch ??
                                                false
                                            }
                                            hasSafeSearch={
                                                portal.safeSearches.length > 0
                                            }
                                            age={portal.age}
                                            globalAge={props.globalAge}
                                            text={portal.text}
                                            toggleAllowed={() => {
                                                setPortals((old) =>
                                                    // TODO: Validate that `old` contains all the available portals
                                                    old.map((p) => {
                                                        return p.name ===
                                                            portal.name
                                                            ? {
                                                                  ...p,
                                                                  active: !p.active,
                                                              }
                                                            : p;
                                                    }),
                                                );
                                            }}
                                            toggleSafeSearch={() => {
                                                setPortals((old) =>
                                                    // TODO: Validate that `old` contains all the available portals
                                                    old.map((p) => {
                                                        return p.name ===
                                                            portal.name
                                                            ? {
                                                                  ...p,
                                                                  safeSearch:
                                                                      !p.safeSearch,
                                                              }
                                                            : p;
                                                    }),
                                                );
                                            }}
                                        />
                                    );
                                })}
                            </>
                        )}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "0.5rem",
                            marginTop: "1rem",
                        }}
                    >
                        <button className="btn btn-primary" type="submit">
                            <i
                                className="icon icon-check"
                                style={{ marginRight: "0.4rem" }}
                            />
                            Submit
                        </button>
                        <button
                            className="btn"
                            type="button"
                            onClick={() => props.setIsEditing(false)}
                        >
                            <i
                                className="icon icon-cross"
                                style={{ marginRight: "0.4rem" }}
                            />
                            Cancel
                        </button>
                    </div>
                </form>
            </td>
        </tr>
    );
};

export const EditChild = withAppContext(injectIntl(Edit));
