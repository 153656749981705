import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { ChildTable } from "../../components/ChildTable/Index";
import { CreateChild } from "../../components/ChildTable/Create-Child";
import withLayout from "../../layout";
import {
    apiFetchChildren,
    apiRemoveChild,
    apiSafeSearchList,
    apiUpdateChild,
} from "../../util/api";
import { adminApiPortalList } from "../../util/adminApi";
// import children from '../../i18n/translations/en/children';
import { AccessibilityMenu } from "../../components/AccessibilityMenu/AccessibilityMenu";

const ChildrenPage = (props) => {
    // Setting state
    const [effectCounter, setEffectCounter] = useState(0);
    const [children, setChildren] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [availablePortals, setAvailablePortals] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await apiFetchChildren();
            setChildren(result);

            const portals = await adminApiPortalList();
            setAvailablePortals(portals);

            setIsLoading(false);
        };

        fetchData();
    }, [effectCounter, setIsLoading, setChildren, setAvailablePortals]);

    // CRUD operations
    const addItem = (item) => {
        setChildren((old) => [...old, item]);
    };

    const deleteItem = async (id) => {
        await apiRemoveChild(id);
        setEffectCounter((old) => old + 1);
    };

    const updateItem = async (id, updatedItem) => {
        await apiUpdateChild(updatedItem);
        setEffectCounter((old) => old + 1);
    };

    const { formatMessage, locale } = props.intl;

    return (
        <>
            <AccessibilityMenu i18nKey="children" />
            <div
                dangerouslySetInnerHTML={{
                    __html: formatMessage(
                        {
                            id: "children.info",
                        },
                        {
                            locale: locale,
                        },
                    ),
                }}
            />
            <hr />
            <br />
            {showCreate ? (
                <CreateChild
                    showCreate={setShowCreate}
                    addItem={addItem}
                    portals={availablePortals}
                />
            ) : (
                <button className="btn" onClick={() => setShowCreate(true)}>
                    Kind hinzufügen
                </button>
            )}
            <br />
            <br />
            <ChildTable
                items={children}
                updateItem={updateItem}
                deleteItem={deleteItem}
                isLoading={isLoading}
                availablePortals={availablePortals}
            />
        </>
    );
};

const customProps = {
    localeKey: "list",
    withAuth: true,
    title: <FormattedMessage id="children.title" />,
};

export default withLayout(customProps)(injectIntl(ChildrenPage));
