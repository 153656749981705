import React, { useMemo } from "react";

const Item = ({ uuid, item, validator, updateItem, removeItem }) => {
    const error = !!item.trim() && (validator?.(item) ?? void 0);
    return (
        <li
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <span style={{ width: "100%", marginRight: "0.1em" }}>
                <input
                    className={
                        "form-input" +
                        (error ? " is-error" : "") +
                        (!!item.trim() && !error ? " is-success" : "")
                    }
                    type="text"
                    value={item}
                    onInput={(e) => {
                        // @ts-ignore
                        updateItem(uuid, e.target.value);
                    }}
                    disabled={!updateItem}
                />
                <p className="form-input-hint">{error}</p>
            </span>
            <button
                type="button"
                className="btn btn-error btn-sm"
                style={{
                    padding: "0.2rem .6rem",
                    height: "1.8rem",
                }}
                onClick={() => removeItem(uuid)}
            >
                <i className="icon icon-delete"></i>
            </button>
        </li>
    );
};

/**@type {List}*/
export const List = ({ items, accessor, addItem, Component, ...rest }) => {
    return (
        <ul style={{ listStyleType: "none", margin: 0 }}>
            {items.map((item) =>
                Component ? (
                    <Component
                        key={item.uuid}
                        uuid={item.uuid}
                        item={item}
                        {...rest}
                    />
                ) : (
                    <Item
                        key={item.uuid}
                        uuid={item.uuid}
                        item={accessor(item)}
                        {...rest}
                    />
                ),
            )}
            {addItem && (
                <button
                    type="button"
                    className="btn"
                    style={{ width: "100%" }}
                    onClick={() => addItem()}
                >
                    Add
                </button>
            )}
        </ul>
    );
};

/**@type {CustomList}*/
export const CustomList = ({ items, addItem, component, ...rest }) => {
    const Component = component;
    return (
        <>
            {items.map((item) => (
                <Component
                    key={item.uuid}
                    uuid={item.uuid}
                    item={item}
                    {...rest}
                />
            ))}
            {addItem && (
                <button
                    type="button"
                    className="btn"
                    style={{ width: "100%" }}
                    onClick={() => addItem()}
                >
                    Add
                </button>
            )}
        </>
    );
};
