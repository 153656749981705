import React from "react";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";

/**
 * @function PortalSettings
 * @param {{ name: string, text: string, age: number, globalAge: number; allowed: boolean; toggleAllowed(): void; hasSafeSearch: boolean; safeSearch: boolean; toggleSafeSearch(): void; }} props
 */
export const PortalSettings = (props) => {
    const {
        name,
        allowed,
        toggleAllowed,
        safeSearch,
        toggleSafeSearch,
        hasSafeSearch,
        text,
        age,
        globalAge,
    } = props;

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="card-title h5">{name}</div>
                </div>
                <div className="card-body">
                    <div
                        className="form-group"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <label className="form-switch">
                            <input
                                type="checkbox"
                                checked={allowed}
                                onInput={toggleAllowed}
                            />
                            <i
                                style={{ scale: "1.4" }}
                                className="form-icon"
                            ></i>
                            <FormattedMessage id="portals.portals_allowed" />
                        </label>

                        {hasSafeSearch && (
                            <label className="form-switch">
                                <input
                                    type="checkbox"
                                    checked={allowed && safeSearch}
                                    disabled={!allowed}
                                    onInput={toggleSafeSearch}
                                />
                                <i
                                    style={{ scale: "1.4" }}
                                    className="form-icon"
                                ></i>
                                <FormattedMessage id="portals.portals_use_safe_search" />
                            </label>
                        )}
                    </div>
                </div>
                <div className="card-footer">{text}</div>
            </div>
        </div>
    );
};
